<template>
  <div class="advisor-page">
    <h2 class="title">ADVISOR</h2>
    <section class="page-content">
      <template v-if="dataLength">
        <div class="slider">
          <AdvisorSlider :profileList="advisors" :handleClick="openLightBox" />
        </div>
        <div class="teams-group">
          <p class="title">TEAM GROUP</p>
          <div class="teams">
            <TeamRow
              v-for="(advisor, index) in advisors"
              :key="index"
              :advisor="advisor"
              :reverse="index % 2 !== 0"
              @show-advisor="openLightBox"
            />
            <!-- <TeamRow :reverse="true" /> -->
          </div>
        </div>
      </template>
    </section>
  </div>
  <EachAdvisor
    v-if="showLightBox && dataLength"
    :currentIndex="currentOpenIndex"
    :totalLength="advisors.length"
    :onClose="closeLightBox"
    :profileData="currentAdvisor"
    :toNext="toNext"
    :toPrevious="toPrevious"
  />
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { mapGetters, useStore } from "vuex";
import AdvisorSlider from "@/components/molecules/AdvisorSlider";
import TeamRow from "@/components/molecules/TeamRow";
import EachAdvisor from "./EachAdvisor";
export default {
  name: "Adviser",
  components: { AdvisorSlider, TeamRow, EachAdvisor },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const showLightBox = ref(false);
    const currentOpenIndex = computed(() =>
      advisorList.value.findIndex(
        (o) => o.advisor_id === currentAdvisorId.value
      )
    );
    const currentAdvisorId = computed(
      () => +route.params.id || advisorList.value[0].advisor_id
    );
    const advisorList = computed(() => store.getters.advisors);
    const currentAdvisor = computed(() =>
      advisorList.value.find((o) => o.advisor_id === currentAdvisorId.value)
    );

    const dataLength = computed(() => {
      return store.getters.advisors.length;
    });

    const loading = computed(() => store.state.loading);

    const openLightBox = (id) => {
      router.replace(`/advisors/${id}`);
    };

    const closeLightBox = () => {
      router.replace(`/advisors`);
      showLightBox.value = false;
    };

    const toNext = () => {
      const nextIndex = (currentOpenIndex.value + 1) % advisorList.value.length;
      const nextId = advisorList.value[nextIndex].advisor_id;

      openLightBox(nextId);
    };

    const toPrevious = () => {
      const nextIndex =
        (currentOpenIndex.value - 1 + advisorList.value.length) %
        advisorList.value.length;
      const nextId = advisorList.value[nextIndex].advisor_id;

      openLightBox(nextId);
    };

    watch(
      () => route.params.id,
      (id) => {
        if (id) {
          currentOpenIndex.value = +id;
          showLightBox.value = true;
        }
      },
      { immediate: true }
    );

    return {
      dataLength,
      loading,
      showLightBox,
      openLightBox,
      closeLightBox,
      currentOpenIndex,
      toNext,
      toPrevious,
      currentAdvisor,
    };
  },
  computed: {
    ...mapGetters(["advisors"]),
  },
};
</script>

<style lang="scss" scoped>
.advisor-page {
  @include page;

  .title {
    @include page-title($white);
  }

  .page-content {
    margin-top: 100px;

    @include RWD($tablet) {
      margin-top: 40px;
    }
  }

  .slider {
    margin-bottom: 230px;

    @include RWD($tablet) {
      margin-bottom: 100px;
    }
  }

  .teams-group {
    & > .title {
      margin-bottom: 110px;

      @include RWD($tablet) {
        margin-bottom: 80px;
      }
    }
  }
}
</style>
