<template>
  <div ref="sliderElement" class="advisor-slider">
    <section
      class="item-cards"
      :class="{ 'no-trigger': !trigger }"
      :style="{
        transform: `translateX(-${transformXDistance}px)`,
      }"
    >
      <AdvisorCard
        @click="handleClick(item.advisor_id)"
        v-for="item in renderList"
        :key="item.name"
        :profileData="item"
      />
    </section>
    <section class="indicators">
      <button class="previous" @click="toPrevious">
        <img src="@/images/icons/buttonLeftWhite.svg" />
      </button>
      <button class="next" @click="toNext">
        <img src="@/images/icons/buttonRightWhite.svg" />
      </button>
    </section>
    <section class="numbers">
      <span class="number">{{
        String(centerIndex + 1).padStart(String(profileList.length).length, "0")
      }}</span>
      <span class="dash" />
      <span class="number">{{ profileList.length }}</span>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, inject } from "vue";
import AdvisorCard from "./AdvisorCard";

export default {
  name: "AdvisorSlider",
  components: { AdvisorCard },
  props: {
    profileList: Array,
    isIndicatorsOnTop: Boolean,
    handleClick: Function,
    currentIndex: Number,
  },
  setup(props) {
    const { profileList, isIndicatorsOnTop = false, handleClick } = props;

    const media = inject("media");
    const pictureSize = computed(() => {
      return media.value === "desktop" ? 0.38 : 0.7;
    });
    const oneSlideAmount = 1;
    const sliderElement = ref(null);
    const slideDistance = ref(0);
    const centerIndex = ref(0);
    const trigger = ref(null);

    const transformXDistance = computed(() => {
      const start =
        window.innerWidth * (media.value === "desktop" ? 0.03 : 0.5);

      let move = 1;

      if (trigger.value === "right") {
        move = 2;
      } else if (trigger.value === "left") {
        move = 0;
      }

      return start + slideDistance.value * move;
    });

    const getIndex = (index, arrayLength) => {
      if (index < 0) {
        return arrayLength + index;
      }
      if (index > arrayLength - 1) {
        return index - arrayLength;
      }

      return index;
    };

    const renderList = computed(() => {
      let result = [];
      for (let i = -2; i < 3; i++) {
        const index = getIndex(centerIndex.value + i, profileList.length);
        result[i + 2] = profileList[index];
      }

      return result;
    });

    const handleResize = () => {
      slideDistance.value = sliderElement.value.offsetWidth * pictureSize.value;
    };

    onMounted(() => {
      slideDistance.value = sliderElement.value.offsetWidth * pictureSize.value;
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const toNext = () => {
      trigger.value = "right";

      let timeout = setTimeout(() => {
        trigger.value = null;
        centerIndex.value = getIndex(
          centerIndex.value + oneSlideAmount,
          profileList.length
        );

        clearTimeout(timeout);
      }, 500);
    };

    const toPrevious = () => {
      trigger.value = "left";

      let timeout = setTimeout(() => {
        centerIndex.value = getIndex(
          centerIndex.value - oneSlideAmount,
          profileList.length
        );
        trigger.value = null;

        clearTimeout(timeout);
      }, 500);
    };

    return {
      trigger,
      centerIndex,
      sliderElement,
      toNext,
      toPrevious,
      isIndicatorsOnTop,
      profileList,
      renderList,
      transformXDistance,
      handleClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.advisor-slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;

  .item-cards {
    display: flex;
    flex-wrap: nowrap;
    transition: 0.5s transform linear;

    &.no-trigger {
      transition: none;
    }

    & > div {
      flex: 0 0 30%;

      @include RWD($tablet) {
        flex: 0 0 60%;
      }

      & + div {
        margin-left: 8%;

        @include RWD($tablet) {
          margin-left: 10%;
        }
      }
    }
  }

  .indicators {
    z-index: 10;
    align-self: center;
    display: flex;
    justify-content: space-between;
    width: 72%;
    margin-top: -12px;

    @include RWD($tablet) {
      width: 60%;
      margin-top: 20px;
    }

    & > button {
      width: 50px;
      height: 50px;
      border: 1px solid $white;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      & > img {
        width: 100%;
      }

      @include RWD($mediumLaptop) {
        width: 40px;
        height: 40px;
        padding: 9px;
      }

      @include RWD($tablet) {
        width: 23px;
        height: 23px;
        padding: 4px;
      }
    }
  }

  .numbers {
    margin-left: 33%;
    position: absolute;
    bottom: 12%;

    display: flex;
    align-items: center;

    @include RWD($tablet) {
      margin-left: 18%;
      bottom: 18%;
    }

    & > .number {
      color: $white;
      font-family: "Red Rose";
      font-weight: 400;
      font-size: 35px;
      letter-spacing: 1px;

      @include RWD($mediumLaptop) {
        font-size: 29px;
      }

      @include RWD($tablet) {
        font-size: 17px;
      }
    }

    & > .dash {
      width: 36px;
      height: 1px;
      background-color: $white;
      margin: 0 6px;

      @include RWD($tablet) {
        width: 16px;
        margin: 0 4px;
      }
    }
  }
}
</style>
