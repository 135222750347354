<template>
  <div
    ref="sliderElement"
    class="profile-slider"
    :class="{ 'indicators-on-top': isIndicatorsOnTop }"
  >
    <div class="carousel-wrapper">
      <section
        class="item-cards"
        :style="{
          transition: '0.5s transform linear',
          transform: `translateX(${-transformXDistance}px)`,
        }"
      >
        {{ profileList.students }}
        <StudentProfileCard
          :style="{
            flex: `0 0 ${cardWidth * 100}%`,
          }"
          v-for="(item, i) in profileList"
          :namePosition="namePosition"
          :key="item.name"
          :profileData="item"
          :color="color"
          :marginLeft="i !== 0 ? margin : 0"
          :scale="scale"
        />
      </section>
      <span class="shadows left" :class="{ reverse: reverse }" />
      <span class="shadows right" :class="{ reverse: reverse }" />
    </div>
    <section class="indicators" :style="indicatorsStyle">
      <button class="previous" @click="toPrevious">
        <img src="@/images/icons/buttonLeftWhite.svg" />
      </button>
      <button class="next" @click="toNext">
        <img src="@/images/icons/buttonRightWhite.svg" />
      </button>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import StudentProfileCard from "@/components/atoms/StudentProfileCard";

export default {
  name: "ProfileSlider",
  components: { StudentProfileCard },
  props: {
    namePosition: "indisde" | "outside",
    profileList: Array,
    isIndicatorsOnTop: Boolean,
    oneRowAmount: Number,
    margin: Number,
    cardWidth: Number,
    slidePerTime: Number,
    color: String,
    reverse: Boolean,
    scale: Boolean,
    isInfinite: Boolean,
  },
  setup(props) {
    const {
      profileList = [],
      oneRowAmount = 3,
      isIndicatorsOnTop = false,
      namePosition = "indisde",
      margin = 0.06,
      cardWidth = 0.25,
      slidePerTime = 3,
      color,
      reverse = false,
      scale = false,
    } = props;

    const sliderElement = ref(null);
    const slideDistance = ref(0);
    const startIndex = ref(0);

    onMounted(() => {
      slideDistance.value =
        sliderElement.value.offsetWidth * (cardWidth + margin);
      if (reverse) {
        startIndex.value = profileList.length - 1;
      }

      window.addEventListener("resize", handleResize);
    });

    const profiles = computed(() => {
      if (reverse) {
        startIndex.value = props.profileList.length - 1;
      }
      return props.profileList;
    });

    const transformXDistance = computed(() => {
      if (!sliderElement.value) return 0;

      if (reverse) {
        const amount = startIndex.value - oneRowAmount;
        const distance = amount * slideDistance.value;
        const per = cardWidth - (1 - (cardWidth + margin) * oneRowAmount);
        const startValue = sliderElement.value.offsetWidth * per + distance;
        return startValue;
      }

      return startIndex.value * slideDistance.value;
    });

    const indicatorsStyle = computed(() => {
      const distance =
        (1 - cardWidth * oneRowAmount - margin * (oneRowAmount - 1)) * 100;

      if (reverse) {
        return { marginLeft: `${distance}%` };
      }

      return { marginRight: `${distance}%` };
    });

    const handleResize = () => {
      slideDistance.value =
        sliderElement.value.offsetWidth * (cardWidth + margin);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const toNext = () => {
      if (!reverse) {
        if (profileList.length - startIndex.value <= oneRowAmount) return;
      } else {
        if (startIndex.value === props.profileList.length - 1) return;
      }
      startIndex.value += slidePerTime;
    };

    const toPrevious = () => {
      if (reverse) {
        if (startIndex.value === oneRowAmount - 1) return;
      } else {
        if (startIndex.value === 0) return;
      }
      startIndex.value -= slidePerTime;
    };

    return {
      sliderElement,
      toNext,
      margin,
      oneRowAmount,
      toPrevious,
      isIndicatorsOnTop,
      profileList,
      transformXDistance,
      namePosition,
      cardWidth,
      color,
      indicatorsStyle,
      reverse,
      profiles,
      scale,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  position: relative;

  &.indicators-on-top {
    flex-direction: column-reverse;
    .indicators {
      margin-top: 0;
      margin-bottom: 50px;
    }
  }

  .carousel-wrapper {
    position: relative;

    .item-cards {
      display: flex;
      flex-wrap: nowrap;

      & > div {
        & + div {
          margin-left: 6%;
        }
      }
    }

    .shadows {
      position: absolute;
      height: 100%;
      top: 0;

      &.left {
        left: -10px;
        width: 120px;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );

        &.reverse {
          width: 70px;
        }
      }

      &.right {
        right: 0;
        width: 70px;
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );

        &.reverse {
          width: 120px;
        }
      }
    }
  }

  .indicators {
    display: flex;
    margin-top: 50px;
    margin-bottom: 0;
    margin-left: auto;

    & > button {
      width: 50px;
      height: 50px;
      border: 1px solid $white;
      padding: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        border-left: none;
      }

      & > img {
        width: 100%;
      }

      @include RWD($mediumLaptop) {
        width: 40px;
        height: 40px;
        padding: 9px;
      }
    }
  }
}
</style>
