<template>
  <div class="advisor-slide-card">
    <div
      @mouseenter="showMask = true"
      @mouseleave="showMask = false"
      class="image-wrapper"
    >
      <img :src="profileData.coverImage" alt="student photo" />
      <div class="mask" :class="{ 'is-active': showMask }" />
    </div>
    <div class="name-field">
      <p>{{ profileData.name_en }}</p>
      <p>{{ profileData.name || " " }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "AdvisorCard",
  props: {
    profileData: Object,
  },
  setup(props) {
    // const { profileData = {} } = props;
    const showMask = ref(false);

    return {
      // profileData,
      showMask,
    };
  },
};
</script>

<style lang="scss" scoped>
.advisor-slide-card {
  position: relative;
  width: 100%;

  .image-wrapper {
    width: 100%;
    padding-bottom: 100%;
    clip-path: polygon(0 0, 100% 0%, 100% 96%, 52% 96%, 52% 100%, 0% 100%);
    overflow: hidden;
    cursor: pointer;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.4s;
      background-color: $black;

      &.is-active {
        opacity: 0.3;
      }
    }
  }

  .name-field {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);

    @include RWD($tablet) {
      right: -8px;
      top: 50%;
      transform: none;
    }

    p {
      font-size: 25px;
      line-height: 35px;
      color: $white;
      margin: 0;
      white-space: pre-line;
      letter-spacing: 1px;

      &:first-child {
        font-family: "Red Rose";
        margin-bottom: 5px;

        @include RWD($mediumLaptop) {
          font-size: 19px;
          line-height: 29px;
        }

        @include RWD($tablet) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &:last-child {
        font-family: "Zpix";

        @include RWD($mediumLaptop) {
          font-size: 18px;
          line-height: 29px;
        }

        @include RWD($tablet) {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
