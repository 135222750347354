<template>
  <div class="detail-box">
    <div class="title">
      <img src="@/images/icons/titledeco.svg" />
      <span>{{ title }}</span>
    </div>
    <p v-if="content" class="content">{{ content }}</p>
    <slot v-else name="content" />
  </div>
</template>

<script>
export default {
  name: "EachAdvisorDetailBlock",
  components: {},
  props: {
    title: String,
    content: String,
  },
};
</script>

<style lang="scss" scoped>
.detail-box {
  &:not(:last-child) {
    margin-bottom: 60px;

    @include RWD($mediumLaptop) {
      margin-bottom: 40px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include RWD($tablet) {
      margin-bottom: 20px;
    }

    img {
      width: 65px;
      margin-right: 10px;

      @include RWD($mediumLaptop) {
        width: 48px;
      }
    }

    span {
      font-family: "Zpix";
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      color: $neon;

      @include RWD($mediumLaptop) {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .content {
    font-family: "Zpix";
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: $white;
    text-align: left;
    margin: 0;

    @include RWD($mediumLaptop) {
      font-size: 12px;
      line-height: 24px;
    }
  }
}
</style>
