<template>
  <div class="each-product-lightbox mobile">
    <button @click="onClose" class="close-button">
      <img src="@/images/icons/close-icon.svg" />
    </button>
    <div class="box">
      <div class="content">
        <section class="numbers">
          <span class="number">{{
            String(currentIndex + 1).padStart(String(totalLength).length, "0")
          }}</span>
          <span class="dash" />
          <span class="number">{{ totalLength }}</span>
        </section>
        <img src="@/images/backgrounds/vector-top.png" />
        <div class="main-content">
          <div class="top">
            <p class="name">{{ advisorData.name_en }}</p>
            <div class="experience">
              <span :key="item" v-for="item in advisorData.title">{{
                item
              }}</span>
            </div>
          </div>
          <div class="middle">
            <div class="image-wrapper">
              <img
                :key="advisorData.id"
                :src="
                  advisorData.coverImage ||
                  'https://cdn04.pinkoi.com/pinkoi.site/event/miffy-2020/03_Theme%20shop_content.png'
                "
              />
            </div>
            <p class="name">{{ advisorData.name }}</p>
          </div>
          <div :key="advisorData.name_en" class="bottom">
            <DetailBlock title="導師簡介" :content="advisorData.intro" />
            <DetailBlock title="指導學生" :content="null">
              <template v-slot:content>
                <ul class="students">
                  <li :key="s.name" v-for="s in advisorData.students">
                    <router-link :to="`/portfolios/${s.id}`">{{
                      s.name
                    }}</router-link>
                  </li>
                </ul>
              </template>
            </DetailBlock>
            <DetailBlock
              title="選題方向"
              :content="advisorData.topic_selection_direction"
            />
            <DetailBlock
              title="帶題方式"
              :content="advisorData.topic_direction"
            />
          </div>
          <div class="deco">
            <img class="bar" src="@/images/backgrounds/border-deco-top.svg" />
            <img class="text" src="@/images/backgrounds/advisor-info.svg" />
            <img
              class="bar"
              src="@/images/backgrounds/border-deco-bottom.svg"
            />
          </div>
        </div>
        <img src="@/images/backgrounds/vector-bottom.png" />
        <div class="indicators">
          <button @click="toPrevious">
            <img src="@/images/icons/arrowLeft.svg" />
          </button>
          <button @click="toNext">
            <img src="@/images/icons/arrowRight.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailBlock from "./DetailBlock";
export default {
  name: "EachAdivsorMobile",
  components: { DetailBlock },
  props: {
    advisorData: Object,
    currentIndex: Number,
    totalLength: Number,
    onClose: Function,
    toNext: Function,
    toPrevious: Function,
  },
};
</script>

<style lang="scss" scoped>
.each-product-lightbox.mobile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.9);

  .box {
    width: 100%;
  }

  .numbers {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > .number {
      color: $white;
      font-family: "Red Rose";
      font-weight: 400;
      font-size: 35px;
      letter-spacing: 1px;
    }

    & > .dash {
      width: 36px;
      height: 1px;
      background-color: $white;
      margin: 0 6px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 15px;

    .main-content {
      flex: 1 1 auto;
      padding: 40px 30px 40px 0;
      overflow: scroll;
      position: relative;

      & > .top {
        .name {
          font-family: "Red Rose";
          font-size: 30px;
          line-height: 38px;
          font-weight: 400;
          margin-bottom: 20px;
          letter-spacing: 2px;
          color: $white;
        }

        .experience {
          span {
            display: block;
            font-family: "Zpix";
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 2px;
            color: $white;
          }
        }
      }

      & > .middle {
        display: flex;
        margin-top: 40px;

        .image-wrapper {
          width: 230px;
          padding-bottom: 310px;
          position: relative;
          margin-right: 27px;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          margin: 0;
          color: $white;
          writing-mode: vertical-lr;
          font-family: "Zpix";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 30px;
        }
      }

      & > .bottom {
        margin-top: 60px;
      }

      & > .deco {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        position: absolute;
        top: 40px;
        right: 0;

        img.bar {
          height: 160px;
        }

        img.text {
          height: 80px;
          box-sizing: content-box;
          padding: 20px 0;
        }
      }
    }
  }

  .close-button {
    position: absolute;
    color: white;
    top: 70px;
    right: 70px;

    img {
      width: 100%;
    }

    @include RWD($tablet) {
      width: 50px;
      top: 10px;
      right: 20px;
    }
  }

  .indicators {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 15px;

    button {
      display: block;
      width: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .students {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    & > li {
      flex: 0 0 20%;

      a {
        font-family: "Zpix";
        font-size: 12px;
        font-weight: 500;
        line-height: 32px;
        color: $white;
        text-align: justify;
        margin: 0;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
