<template>
  <div class="team-row">
    <section class="advisor" @click="$emit('show-advisor', advisor.advisor_id)">
      <div class="name">
        <p>{{ advisor.name_en }}</p>
        <p>{{ advisor.name }}</p>
      </div>
      <div class="image-wrapper">
        <img class="photo" :src="advisor.coverImage" />
      </div>
      <div class="intro">
        <p v-text="advisor.experience"></p>
      </div>
    </section>
    <section class="students">
      <ProfileSlider
        v-if="media === 'desktop'"
        key="{1}"
        namePosition="outside"
        :profileList="advisor.students || []"
        :isIndicatorsOnTop="true"
        :oneRowAmount="2"
        :margin="0.04"
        :cardWidth="0.34"
        :slidePerTime="1"
        :reverse="reverse"
        color="white"
      />
      <MobileProfileSwiper
        v-else
        namePosition="outside"
        color="white"
        :margin="(0.06 * 100) / 83"
        :cardWidth="(0.3 * 100) / 83"
        :oneRowAmount="2"
        :profileList="advisor.students || []"
        :startFrom="reverse ? 'right' : 'left'"
        :scale="true"
      />
    </section>
  </div>
</template>

<script>
import ProfileSlider from "@/components/molecules/ProfileSlider";
import MobileProfileSwiper from "@/components/molecules/MobileProfileSwiper";
import { inject } from "@vue/runtime-core";
export default {
  name: "TeamRow",
  components: { ProfileSlider, MobileProfileSwiper },
  props: {
    data: Object,
    reverse: Boolean,
    advisor: Object,
  },
  setup(props) {
    const fakeData = {
      name_en: "YING CHAO KUO",
      name_m: "郭英釗",
      photo: "https://data.whicdn.com/images/327433021/original.jpg",
      titles: [
        "美國加州大學洛杉磯分校 建築碩士",
        "九典聯合建築師事務所 主持建築師",
      ],
      students: [
        {
          nameInMandarin: "周周",
          nameInEnglish: "JHOU, JYUN YI",
          profilePhoto:
            "https://cdn04.pinkoi.com/pinkoi.site/event/miffy-2020/03_Theme%20shop_content.png",
        },
        {
          nameInMandarin: "胖胖",
          nameInEnglish: "JHOU, JYUN YI",
          profilePhoto:
            "https://i1.wp.com/popbee.com/image/2021/02/0201041.jpg?quality=95&",
        },
        {
          nameInMandarin: "胖胖",
          nameInEnglish: "JHOU, JYUN YI",
          profilePhoto:
            "https://i1.wp.com/popbee.com/image/2021/02/0201041.jpg?quality=95&",
        },
      ],
    };

    // const { data = fakeData, reverse } = props;
    const media = inject("media");
    return {
      media,
    };
  },
};
</script>

<style lang="scss" scoped>
.team-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 9%;

  @include RWD($tablet) {
    display: block;
    margin: 0;
  }

  .advisor {
    flex: 0 0 38%;
    position: relative;
    margin-left: 0;
    margin-right: 100px;

    @include RWD($tablet) {
      margin: 50px 53px 70px;
    }

    .image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: calc(100% * 195 / 230);
      clip-path: polygon(
        0 0,
        50% 0%,
        50% 5%,
        100% 5%,
        100% 100%,
        30% 100%,
        30% 92%,
        0% 92%
      );

      @include RWD($tablet) {
        width: 85%;
        padding-bottom: 75%;
        margin-left: auto;
      }
    }

    .photo {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .intro {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 414px;
      height: 135px;
      bottom: 30px;
      left: -55px;
      background-image: url(../../../images/backgrounds/oddName.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      @include RWD($mediumLaptop) {
        width: 310px;
        height: 103px;
      }

      @include RWD($tablet) {
        bottom: 10px;
        left: 0;
      }

      @include RWD($phone) {
        width: 216px;
        height: 72px;
        bottom: -30px;
        left: 0;
        transform-origin: left;
        transform: scale(0.8);
      }

      p {
        font-family: "Zpix";
        color: $white;
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 2px;
        white-space: pre-line;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          line-height: 30px;
        }

        @include RWD($phone) {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 1px;
          position: relative;
          left: 40px;
        }
      }
    }

    .name {
      position: absolute;
      top: -30px;
      left: 10px;
      z-index: 10;

      @include RWD($mediumLaptop) {
        top: -20px;
      }

      p {
        color: $white;

        &:first-child {
          font-family: "Red Rose";
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
          letter-spacing: 0.04em;
          margin-bottom: 18px;

          @include RWD($mediumLaptop) {
            font-size: 34px;
            margin-bottom: 10px;
          }

          @include RWD($tablet) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 6px;
          }
        }

        &:last-child {
          font-family: "Zpix";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1em;

          @include RWD($mediumLaptop) {
            font-size: 18px;
          }

          @include RWD($phone) {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }

  .students {
    flex: 1 1 auto;

    @include RWD($tablet) {
      overflow: hidden;
      margin-left: 17%;
    }
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
    margin-right: 12%;
    margin-left: 0;

    @include RWD($tablet) {
      display: block;
      margin: 0;
    }

    .advisor {
      margin-left: 100px;
      margin-right: 0;

      @include RWD($tablet) {
        margin: 50px 53px 70px;
      }

      .image-wrapper {
        @include RWD($tablet) {
          margin-left: 0;
          margin-right: auto;
        }
      }

      .intro {
        width: 414px;
        height: 143px;
        bottom: 0;
        left: unset;
        right: -24px;
        background-image: url(../../../images/backgrounds/evenName.svg);

        @include RWD($mediumLaptop) {
          width: 316px;
          height: 109px;
        }

        @include RWD($tablet) {
          bottom: -10px;
        }

        @include RWD($phone) {
          width: 216px;
          height: 75px;
          bottom: -53px;
          right: 0;
          transform-origin: right;
          transform: scale(0.8);
        }

        span {
          position: relative;
          left: -10px;

          @include RWD($tablet) {
            left: -30px;
          }
        }
      }

      .name {
        position: absolute;
        top: -25px;
        right: 10px;
        z-index: 10;

        p {
          text-align: right;
        }
      }
    }

    .students {
      @include RWD($tablet) {
        margin-left: 0;
        margin-right: 17%;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 290px;

    @include RWD($tablet) {
      margin-bottom: 145px;
    }
  }
}
</style>
