<template>
  <teleport to="#portal-target">
    <div v-if="media === 'desktop'" class="each-adivsor-lightbox desktop">
      <button @click="onClose" class="close-button">
        <img src="@/images/icons/close-icon.svg" />
      </button>
      <div class="box">
        <div class="content">
          <button @click="toPrevious">
            <img src="@/images/icons/arrowLeft.svg" />
          </button>
          <div class="main-content">
            <section class="numbers">
              3
              <span class="number">{{
                String(currentIndex + 1).padStart(
                  String(totalLength).length,
                  "0"
                )
              }}</span>
              <span class="dash" />
              <span class="number">{{ totalLength }}</span>
            </section>
            <div class="left">
              <div class="image-wrapper" :key="profileData.id">
                <img :src="profileData.coverImage" />
              </div>
            </div>
            <div class="middle">
              <div class="brief">
                <div class="left">
                  <p class="name">{{ profileData.name_en }}</p>
                  <div class="experience">
                    <span v-text="profileData.experience"></span>
                  </div>
                </div>
                <div class="right">
                  <p class="name">{{ profileData.name }}</p>
                </div>
              </div>
              <section :key="profileData.name" class="details">
                <div class="left">
                  <DetailBlock title="導師簡介" :content="profileData.intro" />
                  <DetailBlock title="指導學生">
                    <template v-slot:content>
                      <ul class="students">
                        <li :key="s.name" v-for="s in profileData.students">
                          <router-link :to="`/portfolios/${s.work_id}`">{{
                            s.name
                          }}</router-link>
                        </li>
                      </ul>
                    </template>
                  </DetailBlock>
                </div>
                <div class="right">
                  <DetailBlock
                    title="選題方向"
                    :content="profileData.topic_selection_direction"
                  />
                  <DetailBlock
                    title="帶題方式"
                    :content="profileData.topic_direction"
                  />
                </div>
              </section>
            </div>
            <div class="right">
              <img class="bar" src="@/images/backgrounds/border-deco-top.svg" />
              <img class="text" src="@/images/backgrounds/advisor-info.svg" />
              <img
                class="bar"
                src="@/images/backgrounds/border-deco-bottom.svg"
              />
            </div>
          </div>
          <button @click="toNext">
            <img src="@/images/icons/arrowRight.svg" />
          </button>
        </div>
      </div>
    </div>
    <Mobile
      v-else
      :advisorData="profileData"
      :currentIndex="currentIndex"
      :totalLength="totalLength"
      :toNext="toNext"
      :toPrevious="toPrevious"
      :onClose="onClose"
    />
  </teleport>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from "vue";
import DetailBlock from "./DetailBlock";
import Mobile from "./Mobile";
export default {
  name: "EachAdvisor",
  components: { DetailBlock, Mobile },
  props: {
    currentIndex: Number,
    totalLength: Number,
    onClose: Function,
    profileData: {
      type: Object,
      default: () => ({}),
    },
    toNext: Function,
    toPrevious: Function,
  },
  setup(props) {
    const media = inject("media");
    const fakeData = {
      name_en: "12345",
      name_m: "莊熙平",
      title: [
        "美國加州大學洛杉磯分校 建築碩士",
        "九典聯合建築師事務所 主持建築師",
      ],
      intro:
        "都市更新的意義與都市閒置空間的保存利用，對於未來都市環境的創造與價值觀建立是很重要的。建築師在社會上所扮演的角色，必須是整合各分類專業的。都市更新的意義與都市閒置空間的保存利用，對於未來都市環境的創造與價值觀建立是很重要的。建築師在社會上所扮演的角色，必須是整合各分類專業的。",
      students: [
        { name: "江洋萱", id: 1 },
        { name: "金鐘獎", id: 2 },
        { name: "唐齊揚", id: 3 },
        { name: "顏雅倫", id: 4 },
      ],
      project:
        "都市景觀及複合的商業模式創造文化創意空間景觀及複合的商業模式創造文化創意空間",
      method:
        "希望學生在畢業設計中都能做自己最想做的設計，設計課不只是在教室發生，偶爾一起去看看展覽或是舒服的咖啡廳聊設計。盡情做夢，享受生活。",
    };

    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = "auto";
    });

    return {
      media,
    };
  },
};
</script>

<style lang="scss" scoped>
.each-adivsor-lightbox.desktop {
  @include modal;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 100vh;
  height: 100%;
  overflow: scroll;
  padding: 30px;

  .box {
    margin: auto 0;
  }

  .numbers {
    display: flex;
    align-items: center;
    position: absolute;
    top: -60px;
    left: 0;

    & > .number {
      color: $white;
      font-family: "Red Rose";
      font-weight: 400;
      font-size: 35px;
      letter-spacing: 1px;
    }

    & > .dash {
      width: 36px;
      height: 1px;
      background-color: $white;
      margin: 0 6px;
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    .main-content {
      display: flex;
      background-image: url(../../../images/backgrounds/lightbox-background-desktop.png);
      background-size: 100% 100%;
      padding: 15px;
      margin: 0 70px;
      background-color: $black;
      align-items: center;
      width: 1500px;
      max-width: 80vw;
      position: relative;

      & > .left {
        flex: 1 1 39%;
        margin-right: 50px;
      }

      & > .middle {
        flex: 1 1 57%;
        margin-right: 100px;
        padding: 40px 0;

        .brief {
          display: flex;
          margin-bottom: 70px;

          .left {
            .name {
              font-family: "Red Rose";
              font-size: 40px;
              line-height: 40px;
              font-weight: 400;
              margin-bottom: 30px;
              color: $white;

              @include RWD($mediumLaptop) {
                font-size: 34px;
                margin-bottom: 15px;
              }
            }

            .experience {
              white-space: pre-line;

              span {
                display: block;
                font-family: "Zpix";
                font-size: 16px;
                line-height: 32px;
                color: $white;

                @include RWD($mediumLaptop) {
                  font-size: 12px;
                  line-height: 24px;
                }
              }
            }
          }

          .right {
            margin-left: 60px;

            .name {
              margin: 0;
              color: $white;
              font-family: "Zpix";
              font-size: 22px;
              font-weight: 500;
              letter-spacing: 2px;
              line-height: 40px;

              @include RWD($mediumLaptop) {
                font-size: 16px;
              }
            }
          }
        }

        .details {
          display: flex;

          & > div {
            flex: 1 1 50%;

            &:first-child {
              margin-right: 60px;
            }
          }
        }
      }

      & > .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        position: relative;

        img.bar {
          flex: 0 0 auto;
          height: 250px;

          @include RWD($mediumLaptop) {
            height: 220px;
          }
        }

        img.text {
          flex: 0 0 auto;
          height: 117px;

          @include RWD($mediumLaptop) {
            height: 100px;
          }
        }
      }
    }
  }

  .image-wrapper {
    width: 100%;
    padding-bottom: 100% * 4 / 3;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .close-button {
    margin-left: auto;
    margin-bottom: 20px;
    color: white;
  }

  .students {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    & > li {
      flex: 0 0 25%;

      a {
        font-family: "Zpix";
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        color: $white;
        text-align: justify;
        margin: 0;
        text-decoration: none;

        @include RWD($mediumLaptop) {
          font-size: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
